import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 5×2\\@90% 1RM`}</p>
    <p>{`Lateral Band Walks 5×8/side`}</p>
    <p>{`then,`}</p>
    <p>{`6:00 EMOM                                               `}<em parentName="p">{`(Scale to %)`}</em></p>
    <p>{`Minute 1:00: 12-Power Cleans (115/75)      45%`}</p>
    <p>{`Minute 2:00: 10-Power Cleans (135/95)     55%`}</p>
    <p>{`Minute 3:00: 8-Power Cleans (155/105)     60%`}</p>
    <p>{`Minute 4:00: 6-Power Cleans (175/115)      70%`}</p>
    <p>{`Minute 5:00: 4-Power Cleans (195/135)     75%`}</p>
    <p>{`Minute 6: 2-Power Cleans (215/145)           85%`}</p>
    <p><em parentName="p">{`or…`}</em></p>
    <p>{`Death by Dumbbell Power Cleans (x2)`}</p>
    <p>{`Minute 1:00: 2-DB Power Cleans`}</p>
    <p>{`Minute 2:00: 4-DB Power Cleans`}</p>
    <p>{`Mintue 3:00: 6-DB Power Cleans`}</p>
    <p>{`etc.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      